import { isBefore, isValid, parse } from 'date-fns'

import {
	areAdditionalGuestResidencyFieldsRequired,
	areDocumentDetailsRequired,
	isAddressRequired,
	isGenderRequired,
	isNextDestinationRequired,
	isVisitedCountryRequired,
} from '@/utils'

const EMAIL_DOMAIN_BLACKLIST = [
	'@guest.airbnb.com',
	'@m.expediapartnercentral.com',
	'@guest.booking.com',
	'@airbnb.bookingsync.com',
	'@travelperktrips.com',
	'@guest.trip.com',
]

const isBlacklistedEmail = (email) => !!`${email}`.match(new RegExp(EMAIL_DOMAIN_BLACKLIST.join('|'), 'gi'))

const validateEmail = (email) => email?.includes('@')

const validateAdditionalGuest = (
	{
		guest,
		customer,
		document,
		address,
		propertyCountryCode,
		mainGuestEmail,
		additionalGuests,
	},
) => {
	const errors = []
	const { visitedCountryCode, destinationCountryCode, destinationCity } = guest ?? {}
	const {
		firstName,
		lastName,
		birthDate,
		birthCountryCode,
		birthProvince,
		gender,
		nationalityCode,
	} = customer ?? {}
	const email = customer?.email?.trim().toLowerCase() ?? ''
	const {
		type: documentType,
		number: documentNumber,
		issueCountry: documentIssueCountry,
		issueProvince: documentIssueProvince,
	} = document || {}
	const {
		addressLine1,
		city,
		countryCode,
	} = address || {}

	const documentDetailsVisible = areDocumentDetailsRequired(propertyCountryCode, nationalityCode, countryCode)
	const residenceFieldsVisible = areAdditionalGuestResidencyFieldsRequired(propertyCountryCode)
	const visitedCountryRequired = isVisitedCountryRequired(propertyCountryCode, countryCode)
	const nextDestinationRequired = isNextDestinationRequired(propertyCountryCode, nationalityCode)
	const genderRequired = isGenderRequired(propertyCountryCode)
	const addressRequired = isAddressRequired(propertyCountryCode)

	if (!firstName) errors.push('firstName')
	if (!lastName) errors.push('lastName')
	if (!birthDate) errors.push('birthDate')
	if (propertyCountryCode === 'IT') {
		if (!birthCountryCode) errors.push('birthCountryCode')
		if (!birthProvince && birthCountryCode === 'IT') errors.push('birthProvince')
	}
	if (!nationalityCode) errors.push('nationalityCode')

	if (residenceFieldsVisible) {
		if (addressRequired && !addressLine1) errors.push('addressLine1')
		if (!city) errors.push('city')
		if (!countryCode) errors.push('countryCode')
	}

	if (email && !validateEmail(email)) errors.push('email')
	// note: phone field needs no validation and
	// BE will return an error if phone entered, but not valid

	if (email && email === mainGuestEmail) {
		errors.push('duplicatedGuestEmailUsed')
	}

	const additionalGuestsEmails = additionalGuests?.map((x) => x.customer.email?.trim().toLowerCase())

	// new additional guest is adding with a repeating email
	if (additionalGuests?.length
		&& !customer.id
		&& email !== mainGuestEmail
		&& !!email
		&& additionalGuestsEmails.includes(email)) {
		errors.push('duplicatedGuestEmailUsed')
	}

	// already added additional guest is modified and the email is not unique
	if (additionalGuests?.length
		&& customer.id
		&& email !== mainGuestEmail
		&& !!email
		&& additionalGuests.find((guest) => guest.customer?.id !== customer.id
			&& email === guest.customer?.email?.trim().toLowerCase())
	) {
		errors.push('duplicatedGuestEmailUsed')
	}

	if (genderRequired && propertyCountryCode === 'IT') {
		if (!gender) errors.push('gender')
	}

	if (documentDetailsVisible) {
		if (!documentType) errors.push('documentType')
		if (!documentNumber) errors.push('documentNumber')

		if (propertyCountryCode === 'IT') {
			if (!documentIssueCountry) errors.push('documentIssueCountry')
			if (!documentIssueProvince && documentIssueCountry === 'IT') errors.push('documentIssueProvince')
		}
	}

	if (visitedCountryRequired) {
		if (!visitedCountryCode) errors.push('visitedCountryCode')
	}

	if (nextDestinationRequired) {
		if (!destinationCountryCode) errors.push('destinationCountryCode')
		if (!destinationCity) errors.push('destinationCity')
	}

	return errors
}

const getLatestUpdatedDocument = (documents) => (documents?.length
	? documents.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
	: null)

const validateExpirationDate = (expirationDateString) => {
	const expirationDate = parse(expirationDateString, 'MM/yy', new Date())
	return isValid(expirationDate) && isBefore(new Date(), expirationDate)
}

const parseAdditionalGuests = (
	additionalGuests,
	mainGuest,
	propertyCountryCode,
) => (additionalGuests || [])
	.map((guest) => {
		const {
			id: guestId,
			visitedCountryCode,
			destinationCity,
			destinationCountryCode,
			customer: {
				id: customerId,
				firstName,
				lastName,
				birthDate,
				birthCountryCode,
				nationalityCode,
				gender,
				email,
				phone,
				phoneCountryCallingCode,
				phoneCountryCode,
				customerDocuments,
				customerAddress,
				data,
			},
		} = guest ?? {}
		const document = getLatestUpdatedDocument(customerDocuments)
		const {
			id: documentId,
			type: documentType,
			number: documentNumber,
			issuingCountryCode: documentIssueCountry,
			data: documentData,
		} = document ?? {}
		const {
			addressLine1,
			city,
			countryCode,
		} = customerAddress ?? {}

		const parsedGuest = { visitedCountryCode, destinationCity, destinationCountryCode }
		const parsedCustomer = {
			id: customerId,
			firstName,
			lastName,
			email,
			phone,
			gender,
			phoneCountryCallingCode,
			phoneCountryCode,
			birthDate,
			birthCountryCode,
			birthProvince: data?.italyBirthCommune ?? undefined,
			nationalityCode,
		}
		const parsedDocument = document
			? {
				id: documentId,
				type: documentType,
				number: documentNumber,
				issueCountry: documentIssueCountry,
				issueProvince: documentData?.italyIssuingCommune ?? undefined,
			}
			: null
		const parsedAddress = {
			addressLine1,
			city,
			countryCode,
		}

		return {
			id: guestId,
			guest: parsedGuest,
			customer: parsedCustomer,
			document: parsedDocument,
			address: parsedAddress,
			guestDetailsAreComplete: !validateAdditionalGuest(
				{
					guest: parsedGuest,
					customer: parsedCustomer,
					document: parsedDocument,
					address: parsedAddress,
					propertyCountryCode,
					mainGuestEmail: mainGuest.email?.trim().toLowerCase(),
					additionalGuests,
				},
			).length,
		}
	})

export {
	isBlacklistedEmail,
	validateEmail,
	validateAdditionalGuest,
	getLatestUpdatedDocument,
	validateExpirationDate,
	parseAdditionalGuests,
}
