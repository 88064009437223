import { createSelector } from 'reselect'
import { RootState } from '../store'
import { ReservationState } from 'Types/reservation-state.types'
import { DoorCodeError, DoorCodeModel } from 'Src/models/reservation'
import { transformDoorCodeError } from 'Src/api/transforms/transforms'
import { PropertyKeyType } from './reservation.constants'

const selectReservationState = (state: RootState): ReservationState => state.reservationState

const selectReservation = createSelector<RootState, ReservationState, ReservationState['reservation']>(
	[selectReservationState],
	(reservationState) => reservationState?.reservation,
)

const selectIsCityTaxRequired = createSelector<RootState, ReservationState, ReservationState['isCityTaxRequired']>(
	[selectReservationState],
	(reservationState) => reservationState?.isCityTaxRequired,
)
const selectIsBreakfastCampaignActive = createSelector(
	[selectReservationState],
	(reservationState) => reservationState?.isBreakfastCampaignActive,
)

const selectAskForBusinessLead = createSelector(
	[selectReservationState],
	(reservationState) => {
		const isWhiteLabel = window.location.hostname.includes('notahotel.co')

		return reservationState.shouldCaptureBusinessLead
			&& reservationState.travelReasons?.some(({ value }) => value === 'business')
			&& isWhiteLabel === false
	},
)
export const selectDoorCode = createSelector<RootState, ReservationState['reservation'], DoorCodeModel>(
	[selectReservation],
	(reservation) => {
		const propertyKeys = reservation?.propertyKeys
		const unitPropertyKeys = propertyKeys?.filter((key) => key.type === PropertyKeyType.propertyUnitDoor) ?? []
		const propertyKey = unitPropertyKeys[0] ?? propertyKeys?.find((key) => key.code === null)

		if (propertyKey === undefined) {
			return {
				code: null,
				errorCode: DoorCodeError.NOT_AVAILABLE_YET,
			}
		}

		if (propertyKey.code === null) {
			const errorCode = transformDoorCodeError(propertyKey.errorCode)
			return {
				errorCode,
				code: null,
				availableAt: propertyKey.availableAt,
			}
		}

		return {
			code: propertyKey.code,
			validToDate: propertyKey.validToDate,
		}
	},
)

const selectInitialReservationData = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.initialReservationData,
)

const selectCustomer = createSelector(
	[selectInitialReservationData],
	(reservationState) => reservationState?.customer,
)

const selectReservationHasStarted = createSelector(
	[selectReservation],
	(reservation) => reservation && reservation.status !== 'confirmed',
)

const selectIsCheckInCompleted = createSelector(
	[selectReservation],
	(reservation) => !!reservation?.checkInDate,
)

const selectReservationPropertyCountryCode = createSelector(
	[selectReservation],
	(reservation) => reservation?.property?.countryCode,
)

const selectInitialCreditCardData = createSelector(
	[selectInitialReservationData],
	(reservationState) => ({
		reservationCreditCard: reservationState?.creditCard ?? null,
		guestCreditCards: reservationState?.creditCards ?? [],
	}),
)

const selectCity = createSelector(
	[selectReservation],
	(reservation) => reservation?.property?.city,
)
const selectIsWhiteLabel = createSelector(
	[selectReservation],
	(reservation) => reservation?.property.isWhiteLabel ?? false,
)

export {
	selectReservationState,
	selectReservation,
	selectCustomer,
	selectInitialReservationData,
	selectReservationHasStarted,
	selectIsCheckInCompleted,
	selectInitialCreditCardData,
	selectReservationPropertyCountryCode,
	selectIsBreakfastCampaignActive,
	selectIsWhiteLabel,
	selectIsCityTaxRequired,
	selectCity,
	selectAskForBusinessLead,
}
